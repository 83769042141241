import { useState } from "react";

import "./App.css";

import type { TrainingGroup, TrainingHistory } from "./training/types";
import { TrainingResultsTable } from "./training/results";

function App() {
  const groups: TrainingGroup[] = [
    {
      id: 1,
      exercises: [
        {
          id: 1,
          exercise: {
            id: 1,
            name: "Face pull",
          },
          sets: [
            {
              id: 1,
              set: "W",
              reps: 10,
              factor: {
                amount: 20,
                unit: "kg",
              },
            },
            {
              id: 2,
              set: 1,
              reps: 5,
              factor: {
                amount: 50,
                unit: "kg",
              },
            },
          ],
        },
      ],
    },
    {
      id: 2,
      exercises: [
        {
          id: 2,
          exercise: {
            id: 2,
            name: "Smith machine quad-focused squad",
          },
          sets: [
            {
              id: 1,
              set: "W",
              reps: 10,
              factor: {
                amount: 20,
                unit: "kg",
              },
            },
            {
              id: 2,
              set: 1,
              reps: 5,
              factor: {
                amount: 50,
                unit: "kg",
              },
            },
          ],
        },
      ],
    },
    {
      id: 3,
      exercises: [
        {
          id: 3,
          exercise: {
            id: 3,
            name: "Front foot elevated dumbbell split squat",
          },
          sets: [
            {
              id: 1,
              set: "W",
              reps: 10,
              factor: {
                amount: 20,
                unit: "kg",
              },
            },
            {
              id: 2,
              set: 1,
              reps: 5,
              factor: {
                amount: 50,
                unit: "kg",
              },
            },
          ],
        },
      ],
    },
  ];
  const [results, setResults] = useState({ groups });

  const history: TrainingHistory = {};
  groups.forEach((group) => {
    group.exercises.forEach((exercise) => {
      const value = history[exercise.exercise.id] || {};

      exercise.sets.forEach((set) => {
        value[set.id] = set;
      });
      history[exercise.exercise.id] = value;
    });
  });

  return (
    <div className="App">
      <header className="App-header">
        <TrainingResultsTable {...{ results, setResults, history }} />
      </header>
    </div>
  );
}

export default App;
