export type Unit = "kg" | undefined;

export type Factor = {
  amount: number;
  unit: Unit;
};

export function renderFactor(factor: Factor) {
  let amount = Math.round(factor.amount * 10) / 10;
  if (factor.unit) {
    return (
      <span>
        {amount}&nbsp;{factor.unit}
      </span>
    );
  }
  return <span>{amount}</span>;
}
