import * as types from "./types";
import { renderFactor } from "../helpers/unit";

import { iconPlus } from "../helpers/icon";

export function InlineTrainingSet({
  set,
}: {
  set: types.TrainingSet | undefined;
}) {
  if (!set) {
    return <span>&mdash;</span>;
  }

  return (
    <span>
      {set.reps}×{renderFactor(set.factor)}
    </span>
  );
}

const selectTargetText = (event: React.FocusEvent<HTMLInputElement>) =>
  event.target.select();

function Input(props: React.HTMLProps<HTMLInputElement>) {
  return (
    <input
      className="bg-slate-100 hover:bg-slate-200 rounded-lg py-1 px-3 text-center w-full"
      onFocus={selectTargetText}
      {...props}
    />
  );
}

export function TrainingSet({
  set,
  setSet,
  history,
  exercise,
}: {
  set: types.TrainingSet;
  setSet: React.Dispatch<React.SetStateAction<types.TrainingSet>>;
  history: types.TrainingHistory;
  exercise: types.TrainingExercise;
}) {
  return (
    <tr key={set.id}>
      <td translate="no" className="py-1 pr-1">
        <Input
          value={set.set}
          pattern="[1-9][0-9]{0,}|[wW]"
          onChange={(e) =>
            setSet((set) => ({
              ...set,
              // TODO "W"
              set: +(e.target as HTMLInputElement).value,
            }))
          }
        />
      </td>
      <td translate="no" className="py-1 px-1 text-slate-500">
        <InlineTrainingSet set={(history[exercise.id] || {})[set.id]} />
      </td>
      <td translate="no" className="py-1 px-1">
        <Input
          type="number"
          value={set.reps}
          onChange={(e) =>
            setSet((set) => ({
              ...set,
              reps: +(e.target as HTMLInputElement).value,
            }))
          }
        />
      </td>
      <td translate="no" className="py-1 pl-1">
        <Input
          type="number"
          value={set.factor.amount}
          onChange={(e) =>
            setSet((set) => ({
              ...set,
              factor: {
                ...set.factor,
                amount: +(e.target as HTMLInputElement).value,
              },
            }))
          }
        />
      </td>
    </tr>
  );
}

export function TrainingExercise({
  exercise,
  setExercise,
  history,
}: {
  exercise: types.TrainingExercise;
  setExercise: React.Dispatch<React.SetStateAction<types.TrainingExercise>>;
  history: types.TrainingHistory;
}) {
  const cols = ["set", "prev", "rep", "kg"].map((col) => (
    <th
      className="sticky z-10 top-0 text-xs text-gray-400 font-normal montserrat p-0 uppercase"
      key={col}
    >
      <div className="py-1">{col}</div>
    </th>
  ));

  const rows = exercise.sets.map((set, i) => (
    <TrainingSet
      key={set.id}
      {...{
        set,
        setSet: (fn: React.SetStateAction<types.TrainingSet>) =>
          setExercise((exercise) => ({
            ...exercise,
            sets: [
              ...exercise.sets.slice(0, i),
              typeof fn === "function" ? fn(exercise.sets[i]) : fn,
              ...exercise.sets.slice(i + 1),
            ],
          })),
        exercise,
        history,
      }}
    />
  ));

  return (
    <table className="w-full text-center border-collapse">
      <thead>
        <tr>{cols}</tr>
      </thead>
      <tbody className="align-baseline">{rows}</tbody>
    </table>
  );
}

export function TrainingGroup({
  group,
  setGroup,
  history,
}: {
  group: types.TrainingGroup;
  setGroup: React.Dispatch<React.SetStateAction<types.TrainingGroup>>;
  history: types.TrainingHistory;
}) {
  return (
    <>
      {group.exercises.map((exercise, i) => (
        <form key={exercise.id} className="max-w-lg px-4 md:my-8 mx-auto">
          <h3 className="text-xl text-slate-800 font-bold montserrat leading-6 py-2">
            {exercise.exercise.name}
          </h3>
          <TrainingExercise
            {...{
              exercise,
              setExercise: (
                fn: React.SetStateAction<types.TrainingExercise>
              ) =>
                setGroup((group) => ({
                  ...group,
                  exercises: [
                    ...group.exercises.slice(0, i),
                    typeof fn === "function" ? fn(group.exercises[i]) : fn,
                    ...group.exercises.slice(i + 1),
                  ],
                })),
              history,
            }}
          />

          <button
            className="flex justify-center place-items-center rounded-lg text-sm mt-2 py-2 px-3 bg-slate-100 text-black hover:bg-slate-200 w-full"
            onClick={(e) => e.preventDefault()}
          >
            <span className="size-4 inline-block">{iconPlus}</span>&nbsp;
            <span>Add set</span>
          </button>
        </form>
      ))}
    </>
  );
}

export function TrainingResultsTable({
  results,
  setResults,
  history,
}: {
  results: types.TrainingResults;
  setResults: React.Dispatch<React.SetStateAction<types.TrainingResults>>;
  history: types.TrainingHistory;
}) {
  return (
    <div className="py-2 space-y-8">
      {results.groups.map((group, i) => (
        <div key={group.id}>
          <TrainingGroup
            {...{
              group,
              setGroup: (fn: React.SetStateAction<types.TrainingGroup>) =>
                setResults((results) => ({
                  ...results,
                  groups: [
                    ...results.groups.slice(0, i),
                    typeof fn === "function" ? fn(results.groups[i]) : fn,
                    ...results.groups.slice(i + 1),
                  ],
                })),
              history,
            }}
          />
        </div>
      ))}
    </div>
  );
}
